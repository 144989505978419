<template>
<div>

    <div class="text-center mt-5" v-if="cargando">
        <div class="spinner-border text-primary spinner-border-sm" >
            <span class="sr-only">Loading...</span>
        </div>
        <br>
        Cargando ...
    </div>

    <div class="row" v-if="!cargando">

        <div class="col-md-9">
            <div class="bg-white mb-2">
                <div class="p-5">
                    
                    <span class="h2 titulosg ">
                        <strong>{{datospublicacion.titulo}}</strong>
                    </span>
                    <div class="text-secondary">
                        {{datospublicacion.resumen}}
                    </div>
                
                    <div class="mb-4 mt-4">
                            <div class="d-flex">
                                <span class="bg-light p-2 me-2 rounded">
                                    <strong class="me-2">Desde:</strong> 
                                    <i class="far fa-calendar-alt me-2"></i> {{formatoFechaSimple(datospublicacion.fechainicio)}}
                                </span>
                                <span class="bg-light p-2 me-2 rounded">
                                    <strong class="me-2">Hasta:</strong> 
                                    <i class="far fa-calendar-alt me-2"></i> {{formatoFechaSimple(datospublicacion.fechafin)}}
                                </span>
                                <span class="p-2 ms-2 rounded">
                                    <strong class="me-3">Estado:</strong> 
                                    <span class="badge bg-success" v-if=" datospublicacion.estadocontenido == 1"> Activo </span>                    
                                    <span class="badge bg-secondary" v-if=" datospublicacion.estadocontenido == 2"> Inactivo </span>                    
                                    <span class="badge bg-warning" v-if=" datospublicacion.estadocontenido == 3"> Borrador </span>                    
                                </span>
                            </div>
                    </div>
       
                    <div v-if="datospublicacion.fotoportada != null" :style="'background-image: url('+ archivospublicos +'/contenidos/'+ datospublicacion.fotoportada +'); height: 300px;  background-size: 100% '">
                        <div class="p-4 titulosg text-white">
                            <span class="text-light"> ( Imagen de Portada ) </span>
                        </div>
                    </div>
                    <!-- <img :src="archivospublicos+'/contenidos/'+datospublicacion.fotoportada" class="img-fluid" alt="">  -->
                
                    <div class="mt-4">                
                        <span v-html="datospublicacion.texto"></span>

                        <div class="border-top mt-3 pt-3 mb-3">
                            <strong> <i class="fas fa-paperclip fa-lg"></i> Archivos </strong>
                        </div>
                        <div class="mb-3 mb-2" v-for="item in datospublicacion.archivos" :key="item.idcontenidoarchivos">
                                <i class="fas fa-file-alt  fa-lg text-secondary "></i>
                               {{item.descripcionarchivo}}  <br> <a :href="archivospublicos+'/contenidos/'+item.archivofisico" target="blank" > {{item.archivofisico}}</a>
                        </div>              
                    </div>

                </div>
            </div>
  
        </div>

        <div class="col-md-3">
            
                <div class="mb-3">
                    <button type="button" class="btn btn-primary" @click="editarpublicacion()" ><i class="fas fa-pen"></i> Editar Publicación </button>
                </div>               

                <div class="text-capitalize bg-white p-3 mb-1">
                       <span class="text-secondary"> <i class="far fa-calendar-alt"></i>  Fecha de Creación: </span>  <br>  {{formatoFecha(datospublicacion.created_at)}} 
                </div>
                <div class="text-capitalize bg-white p-3 mb-3">
                       <span class="text-secondary"> <i class="far fa-calendar-alt"></i> Última Actualización:  </span> <br>  {{formatoFecha(datospublicacion.updated_at)}} 
                </div>

                
                <div class="mt-3 mb-3" >
                    <strong> <i class="fas fa-paperclip fa-lg"></i> Archivos  </strong> <span class="badge bg-primary"> {{(datospublicacion.archivos).length}} </span>              
                </div>
                
                <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#modaleliminar">  <i class="fas fa-plus"></i> Agregar </button>

                <ul class="list-group mt-2">
                    <li class="list-group-item" v-for="item in datospublicacion.archivos" :key="item.idcontenidoarchivos">
                        <button class="float-end btn btn-sm btn-outline-danger" title="Eliminar Documento" @click="advertenciadeliminacion(item.idcontenidoarchivos, item.descripcionarchivo)"> <i class="far fa-trash-alt"></i> </button>
                        <button class="float-end btn btn-sm btn-outline-primary me-2" @click="mostrarimagen(item.extension, item.archivofisico)" :title="item.archivofisico">   <i class="fas fa-eye"></i>  </button>  
                        <i class="fas fa-file-alt"></i> {{item.descripcionarchivo}}
                    </li>
                </ul>
          
                <div class="mt-4">
                    <strong>Público para:</strong> <br>
                    Sedes:
                    <ul class="list-group mt-2 mb-3">
                        <li class="list-group-item" v-for="(item, index) in datospublicacion.sedes" :key="index"> <i class="fas fa-check text-primary me-2"></i> {{item.nombreempresasede}} </li>
                    </ul>
                    Grupos:
                    <ul class="list-group mt-2 mb-3">
                        <li class="list-group-item" v-for="(item, index) in datospublicacion.tipospersonas" :key="index">  <i class="fas fa-check text-primary me-2"></i> {{item.nombrecategoria}} </li>
                    </ul>
                    Categorías:
                    <div class="d-flex mt-2 mb-3">
                        <span class="card bg-light p-2 me-2 mb-2" v-for="(item, index) in datospublicacion.categorias" :key="index">   {{item.nombrecategoria}} </span>
                    </div>
                </div>
     
        </div>

    </div>


<!-- Modal -->
<div class="modal fade" id="modaleliminar" tabindex="-1" aria-labelledby="modaleliminarLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modaleliminarLabel">Agregar Documento</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <form @submit.prevent="cargardocumentos" method="POST" enctype="multipart/form-data" id="cargararchivo">
        <div class="modal-body">
          
                <div class="mb-3">
                    <label for="descripciondocumento" class="form-label">Descripción del documento:</label>
                    <input required type="text" class="form-control" id="descripciondocumento" name="descripciondocumento" v-model="descripciondocumento" >
                  </div>
                <div class="mb-3">
                    <label for="formFile" class="form-label"> <i class="fas fa-paperclip"></i> Documento:</label>
                    <input required class="form-control" type="file" id="documentoacargar" name="documentoacargar" @change="getImage" >
                    <span class="text-secondary">Tamaño máximo recomendado para el documento 10Mb</span>
                  </div>
                  
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                    <button type="submit" class="btn btn-primary" :disabled="mostrar">  Guardar</button>
                </div>
            </form>
      </div>
    </div>
  </div>

<!-- Modal Archivos -->
  <div class="modal fade" id="modalarchivo" tabindex="-1" aria-labelledby="modalarchivoLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalarchivoLabel">Archivo</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">

                    <span v-html="archivoview"> </span>
     
                <!-- <object :data="archivoview" ></object> -->
                <!-- <iframe :src="archivoview" class="border" style="width: 100%; " frameborder="0"></iframe> -->
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
  
</div>
</template>

<script>

import { ref, onMounted, getCurrentInstance  } from 'vue'
import axios from 'axios'
import moment from 'moment'

import { useRoute, useRouter } from 'vue-router'

export default {
    props: ['id'],
    setup(props) {

        const router = useRouter()
        const route = useRoute()

        const cargando = ref(true)
        const mostrar = ref(false)
        
        const datospublicacion = ref({})
        const iddocumento = ref()
        const documentoacargar = ref(null)
        const descripciondocumento = ref(null)

        const archivoview = ref(null)

        const consultarpublicacion = () => {
            axios.get('/api/infopublicacion/'+props.id).then( response => {

                datospublicacion.value=response.data
                iddocumento.value=response.data.idcontenido
                cargando.value = false;

            }).catch( error => console.log(error))
        }

        const getImage = (event) => {
              documentoacargar.value = event.target.files[0];
        }        
        
        const cargardocumentos = () => {
            
            mostrar.value = true

            let data = new FormData();
            data.append('idcontenido', props.id)
            data.append('descripcion', descripciondocumento.value)
            data.append('documento', documentoacargar.value)

            axios.post('/api/cargardocumentospublicacion', data ).then( response => {

                console.log(response.data)

                if(response.data !== "Error"){
                    swal("Bien!", "El archivo ha sido cargado correctamente!", "success") 
                }else{
                    swal(":(", "Ocurrrio un Error, Intenteemos nuevamente!", "error");     
                }
                
                descripciondocumento.value=''
                mostrar.value = false
                document.getElementById("cargararchivo").reset();
                
                consultarpublicacion()

            }).catch( error => console.log(error))
        }


        const advertenciadeliminacion = (id, nombre) =>  {
            swal({
                title: "Eliminar?",
                text: "El archivo "+nombre+" será eliminado desea continuar!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                })
                .then((willDelete) => {
                if (willDelete) {                
                    eliminardocumento(id)
                } else {
                  
                }
                })
        }

        const eliminardocumento = (iddocumento) => {
            axios.get('/api/eliminardocumento/'+iddocumento).then( response => {
                    
               if(response.data == "ok")
               {
                swal("Bien!", "Archivo eliminado correctamente!", "success")                    
               }else{
                swal(":(", "Ocurrrio un Error, Intenteemos nuevamente!", "error")    
               }

               consultarpublicacion()

                    
            }).catch( error => { console.log(error) })
        }

        const mostrarimagen = (extrecibida, archivo) => {
            
            axios.get('/api/getdocument/'+archivo).then(response => {
                
                let ventanaarchivos = new bootstrap.Modal(document.getElementById('modalarchivo'))

                let extensionesmodal = ['jpg', 'png', 'jpeg', 'gif']
                if(extensionesmodal.includes(extrecibida)){
                    
                    archivoview.value = '<img src="'+response.data+'" class="img-fluid">'
                    // <iframe :src="archivoview" class="border" style="width: 100%; " frameborder="0"></iframe> 
                    ventanaarchivos.show()

                }
                if(extrecibida=="pdf"){

                    archivoview.value = '<object data="'+response.data+'" class="w-100"  style="min-height: 500px;"></object>'
                    ventanaarchivos.show()

                }

                if(extrecibida != "pdf" && !extensionesmodal.includes(extrecibida)){
                    window.open(response.data)
                }

                
            }).catch( error => {

                swal(":(", "No es posible mostrar el archivo", "error")    

            })
            
        }

        const editarpublicacion = () => {

            router.push({ path: '/publicaciones/editar/'+props.id })

        } 

        onMounted(() => {
            consultarpublicacion()  
        })

        const formatoFecha = (date) => {
            return moment(date).format('MMMM D [de] YYYY, h:mm:ss a');
        }

        const formatoFechaSimple = (date) => {
            return moment(date).format('MMMM D [de] YYYY');
        }

        return {
            mostrar,
            cargando,
            datospublicacion,
            documentoacargar,
            descripciondocumento,
            archivoview,

            //Funciones
            formatoFecha,
            formatoFechaSimple,
            cargardocumentos,
            eliminardocumento,
            getImage,
            advertenciadeliminacion,
            mostrarimagen,
            editarpublicacion
        }

    }
}
</script>

<style>

</style>
